import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getAuth, onAuthStateChanged, isSignInWithEmailLink, signInWithEmailLink, User, Unsubscribe } from "firebase/auth";
import { checkUserInterestExists } from "../firebaseFunctions";
import { CompleteProfile } from './CompleteProfile/CompleteProfile';
import styles from './signIn.module.css';
import { getErrorMessage } from '../common/ErrorMessage';
import { logErrorToBackend } from '../common/logErrorToBackend';

export const EmailLinkAuthenticator: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const location = useLocation();
    const auth = getAuth();
    const [user, setUser] = useState<User | null | boolean>(null);
    const [hasInterests, setHasInterests] = useState<boolean | null>(null);
    const [isSignInLink, setIsSignInLink] = useState<boolean | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [manualEntryEmail, setManualEntryEmail] = useState<string>('');
    const [loadingEmailSignIn, setLoadingEmailSignIn] = useState<boolean>(false);
    const [loadingCheckInterests, setLoadingCheckInterests] = useState<boolean>(false);
    const [isFallbackToManualEntry, setIsFallbackToManualEntry] = useState<boolean>(false);

    useEffect(() => {
        const hasSuccessfullySignedIn = sessionStorage.getItem('hasSuccessfullySignedIn') === 'true';
        console.log(`Has attempted login: ${hasSuccessfullySignedIn}`);
    
        let authUnsubscribe: Unsubscribe | null = null;
    
        const authUnsubFn = () => {
            return onAuthStateChanged(auth, (user) => {
                if (user) {
                    setUser(user);
                    console.log('User is signed in - EmailLinkAuthenticator');
                    checkInterests(user.uid);
                } else {
                    sessionStorage.setItem('hasSuccessfullySignedIn', 'false');
                    console.log('User is not signed in - EmailLinkAuthenticator');
                    setUser(false);
                }
            });
        };
    
        authUnsubscribe = authUnsubFn();
    
        const handleUrlChange = () => {
            const isSignIn = isSignInWithEmailLink(auth, window.location.href);
            setIsSignInLink(isSignIn);

            if (!hasSuccessfullySignedIn) {
                setLoadingEmailSignIn(true);
            
                if (isSignIn) {
                    const email = window.localStorage.getItem('emailForSignIn');
                    if (email) {
                        console.log(`Email link sign in attempted with email: ${email}, url: ${window.location.href}`);
                        signInWithEmailLink(auth, email, window.location.href)
                            .then((result) => {
                                sessionStorage.setItem('hasSuccessfullySignedIn', 'true'); // In case user refreshes the page, with the api key in the browser url
                            })
                            .catch((error) => {
                                console.error(error);
                                setErrorMessage('An error occurred during sign in. Please try again.');
                                const errorMessage = getErrorMessage(error);
                                logErrorToBackend(`Error signing in with email link: ${errorMessage}`);
                            }).finally(() => {
                                setLoadingEmailSignIn(false);
                            });
                    } else {
                        setLoadingEmailSignIn(false);
                        setIsFallbackToManualEntry(true);
                        logErrorToBackend("Email link sign in attempted without email in local storage");
                    }
                } else {
                    setLoadingEmailSignIn(false);
                    console.log(`This page is not a sign in link.`);
                }
            }
        };
    
        // Call handleUrlChange once to handle the initial URL
        handleUrlChange();
    
        return () => {
            authUnsubscribe?.();
        };
    }, [auth, location]);
    

    const checkInterests = async (userId: string) => {
        setLoadingCheckInterests(true);
        try {
            const doesExist = await checkUserInterestExists(userId);
            setHasInterests(doesExist);
            console.log('User has interests - EmailLinkAuthenticator: ', doesExist);
        } catch (error) {
            console.error('Error checking user interest: ', error);
            setHasInterests(false); // Handle error case
            const errorMessage = getErrorMessage(error);
            logErrorToBackend(`Error checking user interests: ${errorMessage}`);
        } finally {
            setLoadingCheckInterests(false);
        }
    };

    const handleProfileCompletion = () => {
        // Logic to execute after the profile is updated
        console.log('Profile update completed');
        setHasInterests(true);
    };

    const handleSignInWithManualEmail = () => {
        setLoadingEmailSignIn(true);
        signInWithEmailLink(auth, manualEntryEmail, window.location.href)
            .then((result) => {
                sessionStorage.setItem('hasSuccessfullySignedIn', 'true');
            })
            .catch((error) => {
                console.error(error);
                setErrorMessage('An error occurred during sign in. Please try again.');
                const errorMessage = getErrorMessage(error);
                logErrorToBackend(`Error signing in with email link manually: ${errorMessage}`);
            }).finally(() => {
                setLoadingEmailSignIn(false);
            });
    };

    if (errorMessage) {
        return <div className="error-message centered-screen">{errorMessage}</div>; // Display the error message if present
    }

    if (loadingCheckInterests || loadingEmailSignIn) {
        return <div className="centered-screen"><div className="loader"></div></div>;
    } else if (isFallbackToManualEntry && (user == false || user == null)) {
        return (
            <>
                <div className={styles.title}>DiscoverDo</div>
                <div className={`mx-5 mt-5 ${styles.desktopBox}`}>
                    <div className="text-left-align">
                        <h2 className="mb-3 blueText">Complete Sign In</h2>
                        <p>Please re-enter your email to continue.</p>
                    </div>
                    <input
                        type="email"
                        placeholder="Email"
                        onChange={(e) => setManualEntryEmail(e.target.value)}
                        className={`${styles.textInput}`}
                    />
                    <div className="centered-horizontal mt-3">
                        <button
                            onClick={handleSignInWithManualEmail}
                            className="custom-button"
                        >
                            Sign In
                        </button>
                    </div>
                </div>
            </>
        );
    } else if (isSignInLink == true && (user == null || user == false)) {
        return <div className="centered-screen"><h6>Sorry, something went wrong.</h6></div>;
    } else if (isSignInLink == true && hasInterests === false) {
        return <CompleteProfile onCompletion={handleProfileCompletion} />;
    } else {
        return <>{children}</>;
    }
};

export default EmailLinkAuthenticator;
