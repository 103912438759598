import { useState } from 'react';
import { getAuth, Auth } from "firebase/auth";

import { useLocation } from "react-router-dom";
import styles from './signIn.module.css';
import axios from 'axios';
import { useMediaQuery } from 'react-responsive';

export const SignIn = () => {
  const location = useLocation();
  const [email, setEmail] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const isMobile = useMediaQuery({ maxWidth: 767 });

  const sendSignInLink = async () => {
    const currentUrl = window.location.href;
    const baseUrl = currentUrl.split('/').slice(0, 3).join('/'); // Extracts the base URL (e.g., http://localhost:3000)
    const fromRoute = location.state?.from || baseUrl;

    setProcessing(true);
    setErrorMessage('');

    try {
      console.log(`Action code settings. From Route: ${fromRoute.toString()}`)
      console.log(`Href now: ${window.location.href}`)
      await sendPasswordlessSignInRequest(email, fromRoute);
      window.localStorage.setItem('emailForSignIn', email);
      setEmailSent(true);
      console.log("Sent sign-in by email link")
    } catch (error) {
      console.error(error);
      setErrorMessage('Failed to send sign-in link. Please try again later.');  // Set error message on failure
    } finally {
      setProcessing(false); // Reset processing state here
    }
  };

  return (
    <>
      <div className={styles.title}>DiscoverDo</div>
      <div className={`mx-5 mt-5 ${isMobile ? '' : styles.desktopBox}`}>
        <div className="text-left-align">
          <h2 className="mb-3 blueText">Sign In</h2>
          <p>Please enter the details below to continue.</p>
        </div>
        <input
          type="email"
          placeholder="Email"
          onChange={(e) => setEmail(e.target.value)}
          className={`${styles.textInput}`}
        />
        <div className="centered-horizontal mt-3">
          <button
            onClick={sendSignInLink}
            disabled={processing || emailSent}
            className="custom-button"
          >
            Send Sign-In Link to your email
          </button>

          {processing &&
            <div className={styles.loaderWrapper}>
              <div className="loader"></div>
            </div>
          }
        </div>

        <div className="centered-horizontal mt-3">
          {true && (
            <p className="mt-3" style={{ color: 'red' }}>
              {errorMessage}
            </p>
          )}
        </div>

        <div className="centered-horizontal mt-3">
          {emailSent && (
            <p className="mt-3">
              An email has been sent to your mailbox. You may shut the browser.
            </p>
          )}
        </div>
      </div>
    </>
  );
};

async function sendPasswordlessSignInRequest(email: string, redirectUrl: string) {
  try {
    const response = await axios.post('https://australia-southeast1-discover-do.cloudfunctions.net/sendPasswordlessEmail', {
      email: email,
      redirectUrl: redirectUrl
    });

    // Handle response here, e.g., notify the user to check their email
    console.log(response.data);
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      // Handle Axios errors here
      console.error("Axios error sending sign-in link:", error.response?.data || error.message);
    } else if (error instanceof Error) {
      // Handle generic errors here
      console.error("Error sending sign-in link:", error.message);
    } else {
      // Handle errors that are neither AxiosError nor Error instances
      console.error("Unknown error sending sign-in link:", error);
    }
    throw new Error("Failed to send sign-in link.");
  }
}
