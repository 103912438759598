import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const UnsubscribeEmail = () => {
    const { token } = useParams(); // Assuming your route is "/unsubscribe/:token"
    const [status, setStatus] = useState('Unsubscribing...');
    const [loading, setLoading] = useState(true); // Add loading state

    useEffect(() => {
        // Function to call the backend API
        const unsubscribeUser = async () => {
            setLoading(true); // Start loading
            try {
                await axios.post('https://australia-southeast1-discover-do.cloudfunctions.net/unsubscribeEmails', {
                    token: token, // Include the token here if you want it in the body
                    // Include any other data your API expects
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        // Any other headers your API needs
                    }
                });
                console.log("unsubscribed successfully.");
                // Handle success response
                setStatus('You have been successfully unsubscribed.');
            } catch (error) {
                console.error('Error unsubscribing:', error);
                setStatus('An error occurred. Please try again later.');
            } finally {
                setLoading(false); // End loading
            }
        };

        if (token) {
            unsubscribeUser();
        } else {
            setStatus('Invalid request. No token provided.');
            setLoading(false); // In case there's no token, end loading
        }
    }, [token]); // This effect depends on the token

    return (
        <div className="centered-container">
            <h2 style={{ marginTop: '20px' }}>Unsubscribe</h2>
            {/* Display loading message or status */}
            {loading ? <div className="centered-screen"><div className="loader"></div></div> : <p style={{ marginTop: '20px' }}>{status}</p>}
        </div>
    );
};

export default UnsubscribeEmail;
