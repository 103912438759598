// src/firebase.ts (or firebaseConfig.ts)

import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDmI-zw0Mz1JAmBCWVH444DAbGO7tgvby8",
  authDomain: "discover-do.firebaseapp.com",
  projectId: "discover-do",
  storageBucket: "discover-do.appspot.com",
  messagingSenderId: "69578224731",
  appId: "1:69578224731:web:6efc869133ff06523ffb7e",
  measurementId: "G-1ZB0NLKB0T"
};
console.log("Done init of firebase.")
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

export { app, analytics, logEvent, db };
