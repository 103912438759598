import { useEffect, useState } from "react";
import React from 'react';
import { Navigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { checkUserInterestExists } from "../firebaseFunctions";
import { CompleteProfile } from "./CompleteProfile/CompleteProfile";

export const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const auth = getAuth();

    // Initialize isAuthenticated as null to represent the unknown state
    const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
    const [hasInterests, setHasInterests] = useState<boolean | null>(null);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                console.log("Checking user interests state");
                try {
                    const doesExist = await checkUserInterestExists(user.uid);
                    console.log(`User exists: ${doesExist}`);
                    setHasInterests(doesExist);
                } catch (error) {
                    console.error('Error checking user interest: ', error);
                }
                setIsAuthenticated(true);
            } else {
                // Set isAuthenticated to false if user is null (unauthenticated)
                setIsAuthenticated(false);
                setHasInterests(false);
            }
        });

        return () => {
            unsubscribe();
        };
    }, [auth]);

    const handleProfileCompletion = () => {
        // Logic to execute after the profile is updated
        console.log('Profile update completed');
        setHasInterests(true);
    };

    // Render based on the authentication and interests states
    if (isAuthenticated === null || (isAuthenticated === true && hasInterests === null)) {
        return <div className="centered-screen"><div className="loader"></div></div>;  // Loading state when authentication status is unknown
    } else if (isAuthenticated && hasInterests === true) {
        return <>{children}</>;  // Authenticated and has interests
    } else if (isAuthenticated && hasInterests === false) {
        return <CompleteProfile onCompletion={handleProfileCompletion} />
    } else {
        return <Navigate replace to="/signin" state={{ from: window.location.href }} />;  // Unauthenticated
    }
};
