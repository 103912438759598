import React, { FC } from 'react';
import styles from './MobileHeader.module.css'; // Your mobile-specific styles
import { User } from "firebase/auth";
import SettingsIcon from './SettingsIcon';
import { UserProfile } from '../../entities/UserProfile';

interface MobileHeaderProps {
    isAuthenticated: boolean;
    user: UserProfile | null;
    isSettingsDropDownVisible: Boolean;
    signInOrRegisterClicked: (event: React.MouseEvent<HTMLButtonElement>) => void;
    signOutClicked: (event: React.MouseEvent<HTMLButtonElement>) => void;
    onEditProfileClicked: (event: React.MouseEvent<HTMLButtonElement>) => void;
    onSettingsIconClicked: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const MobileHeader: FC<MobileHeaderProps> = ({ isAuthenticated, user, isSettingsDropDownVisible, signInOrRegisterClicked, signOutClicked, onEditProfileClicked, onSettingsIconClicked }) => {
    return (
        <div className={styles.headerContainer}>
            <div className={styles.topRow}>
                <div className={styles.companyName}>DiscoverDo</div>
                <div className={styles.actionButtons}>
                    {isAuthenticated && (
                        <>
                        <SettingsIcon isSettingsDropDownVisible={isSettingsDropDownVisible} onSignOut={signOutClicked} onEditProfile={onEditProfileClicked} onSettingsIconClicked={onSettingsIconClicked}/>
                        </>
                    )}
                    {!isAuthenticated && (
                        <button className='custom-button' onClick={signInOrRegisterClicked}>Log In/Register</button>
                    )}
                </div>
            </div>
            {isAuthenticated && (
                <>
                    <div className={styles.welcomeTextRow}>👋 Welcome,</div>
                    <div className={styles.usernameRow}>{user?.name}</div>
                </>
            )}
        </div>
    );
};

export default MobileHeader;
