import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

type NavigationOptions = {
  replace?: boolean;
  // Other navigation options as needed
};

// Meant to handle back navigation better than standard nav component
export function useCustomNavigate() {
    const navigate = useNavigate();
    const location = useLocation();
    const [hasNavigated, setHasNavigated] = useState(false);

    useEffect(() => {
        // Listen for changes in location
        return () => {
            if (location.pathname) {
                setHasNavigated(true);
            }
        };
    }, [location]);

    const customNavigate = (to: any, options: NavigationOptions = {}) => {
        if (to === -1 && !hasNavigated) {
            // No navigation has occurred, go to fallback
            options.replace = true; 
            navigate('/', options);
        } else {
            // Navigate as requested
            navigate(to, options);
        }
    };

    return customNavigate;
}
