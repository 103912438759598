import React, { useEffect, useState } from 'react';
import styles from './Carousel.module.css';

interface CarouselProps {
    images: string[];
}

const Carousel: React.FC<CarouselProps> = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const goToPrevious = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? images.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
    };

    const goToNext = () => {
        setCurrentIndex(prevIndex => (prevIndex + 1) % images.length);
    };

    useEffect(() => {
        // Set up a timer to go to the next image
        const timer = setInterval(goToNext, 2000);

        // Clear the interval on component unmount
        return () => clearInterval(timer);
    }, [images]);

    return (
        <div className={styles.carouselContainer}>
            <div className={styles.carouselWrapper}>
                <button onClick={goToPrevious} className={styles.leftArrow}>&lt;</button>
                <button onClick={goToNext} className={styles.rightArrow}>&gt;</button>
                <div className={styles.carouselContentWrapper}>
                    {images.map((image, index) => (
                        <img
                            key={index}
                            src={image}
                            className={`${styles.carouselImage} ${index === currentIndex ? styles.active : ''}`}
                            alt={`Slide ${index}`}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Carousel;
