import { doc, getDoc, setDoc, collection, getDocs } from "firebase/firestore";
import { db } from './firebaseConfig'
import { UserInterestCategories, UserProfile } from "./entities/UserProfile";

export const updateUserProfile = async (userId: string, userProfile: UserProfile) => {
    try {
        const docRef = doc(db, "userProfile", userId); // Reference to the user's document

        // Combine user interests and postcode into one object
        const userProfileData = {
            ...userProfile
        };

        // Update the document with the combined data
        await setDoc(docRef, userProfileData);

        console.log("User profile updated successfully");
    } catch (e) {
        console.error("Error updating user profile: ", e);
    }
};

export const getUserProfile = async (userId: string): Promise<UserProfile | null> => {
    try {
        const docRef = doc(db, "userProfile", userId); // Reference to the user's document
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            console.log("User profile retrieved successfully");
            return docSnap.data() as UserProfile;
        } else {
            console.log("No such user profile exists");
            return null;
        }
    } catch (e) {
        console.error("Error getting user profile: ", e);
        return null;
    }
};


export const checkUserInterestExists = async (userId: string): Promise<boolean> => {
    try {
        const docRef = doc(db, "userProfile", userId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            return true;
        } else {
            console.log("No such document!");
            return false;
        }
    } catch (e) {
        console.error("Error fetching document: ", e);
        throw e;
    }
}

export const getCategories = async (): Promise<UserInterestCategories[] | null> => {
    try {
        const snapshot = await getDocs(collection(db, 'interestCategories'));
        const categoriesList = snapshot.docs.map(doc => ({
            id: doc.id,
            name: doc.data().name,
            emoji: doc.data().emoji
        }));

        return categoriesList;
    } catch (error) {
        console.error("Error fetching categories: ", error);
        return null;
    }
};

