import ReactDOM from 'react-dom/client';
import App from './components/App';
import { app, db, logEvent } from './firebaseConfig'
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';

// Necessary for firebase config to actually be imported and run
const appUsage = app;
const dbUsage = db;
const logEventUsage = logEvent;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
    <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
