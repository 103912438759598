import '../styles/main.css';
import {
  Routes,
  Route,
  BrowserRouter,
  useLocation
} from "react-router-dom";
import { ProtectedRoute } from './ProtectedRoute'
import { Home } from './Home/Home';
import { SignIn } from './signIn';
import EmailLinkAuthenticator from './EmailLinkAuthenticator';
import { CompleteProfile } from './CompleteProfile/CompleteProfile';
import { EditProfile } from './CompleteProfile/EditProfile';
import { useCustomNavigate } from './useCustomNavigate';
import UnsubscribeEmail from './UnsubscribeEmail/UnsubscribeEmail';
import { usePageTracking } from '../common/hooks/analytics';

function App() {
  return (
    <BrowserRouter>
      <AppInner />
    </BrowserRouter>

  );
}

function AppInner() {
  const customNavigate = useCustomNavigate();
  
  const location = useLocation();
  usePageTracking(location);

  return (
    <EmailLinkAuthenticator>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/completeProfile" element={<ProtectedRoute><CompleteProfile onCompletion={function (): void {
          customNavigate(-1);
        }} /></ProtectedRoute>} />
        <Route path="/editProfile" element={<ProtectedRoute><EditProfile onCompletion={function (): void {
          customNavigate(-1);
        }} /></ProtectedRoute>} />
        <Route path="/unsubscribe/:token" element={<UnsubscribeEmail />} />
      </Routes>
    </EmailLinkAuthenticator>);
};

export default App;
