// SettingsIcon.tsx
import React, { FC, useState } from 'react';
import DropdownMenu from './DropdownMenu';
import styles from './SettingsIcon.module.css'; // Adjust path as needed

interface SettingsIconProps {
    isSettingsDropDownVisible: Boolean;
    onSignOut: (event: React.MouseEvent<HTMLButtonElement>) => void;
    onEditProfile: (event: React.MouseEvent<HTMLButtonElement>) => void;
    onSettingsIconClicked: (event: React.MouseEvent<HTMLButtonElement>) => void;
  }

const SettingsIcon: FC<SettingsIconProps> = ({ isSettingsDropDownVisible, onSignOut, onEditProfile, onSettingsIconClicked }) => {
  return (
    <div className={styles.settingsContainer}>
      <i className="fas fa-cog" onClick={onSettingsIconClicked}></i> {}
      {isSettingsDropDownVisible && (
        <DropdownMenu onSignOut={onSignOut} onEditProfile={onEditProfile} />
      )}
    </div>
  );
};

export default SettingsIcon;
