import { UserInterestCategories, UserProfile } from "../../entities/UserProfile";
import { updateUserProfile } from "../../firebaseFunctions";
import { analytics, logEvent } from '../../firebaseConfig';
import { getErrorMessage } from "../../common/ErrorMessage";

export const updateUserPrefs = async (userId: string, name: string, selectedCategories: Map<string, UserInterestCategories>, pastMoments: string, postCodeInput: string, isSubscribedToEmails: boolean, onCompletion: () => void) => {
    if (userId) {
        const userProfile: UserProfile = {
            name,
            userId: userId,
            items: Array.from(selectedCategories.values()),
            postcode: postCodeInput,
            pastMoments: pastMoments,
            isSubscribedToEmails
        };

        try {
            await updateUserProfile(userId, userProfile);
            onCompletion();
        } catch (error) {
            console.error("Error updating user preferences: ", error);
            const errorMessage = getErrorMessage(error);
            logEvent(analytics, 'exception', {
                description: `Error updating user preferences: ${errorMessage}`,
                fatal: false
            });
        } finally {
        }
    }
};

export function validateUserProfileForm(name: string, postCodeInput: string, selectedCategoriesSize: number) {
    if (!name.trim()) {
        return { isValid: false, message: "Please enter your name." };
    }
    if (!postCodeInput.trim()) {
        return { isValid: false, message: "Please enter a postcode." };
    } else if (selectedCategoriesSize === 0) {
        return { isValid: false, message: "Please select at least one category." };
    }
    return { isValid: true, message: "" }; // Form is valid
}
