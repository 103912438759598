import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { analytics, logEvent } from '../../firebaseConfig';

export function usePageTracking(location: any) {
    useEffect(() => {
        logEvent(analytics, 'page_view', {
            page_path: location.pathname,
        });
    }, [location]);
}