import axios from "axios";

export function logErrorToBackend(errorMessage: string) {
    axios.post('https://australia-southeast1-discover-do.cloudfunctions.net/logErrorToGoogle', {
        errorMessage: errorMessage
    })
    .then(response => {
        console.log('Error reported successfully:', response.data);
    })
    .catch(error => {
        console.error('Failed to report error:', error);
    });
}