// DropdownMenu.tsx
import React, { FC } from 'react';
import styles from './DropdownMenu.module.css'; // Adjust path as needed

interface DropdownMenuProps {
  onSignOut: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onEditProfile: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const DropdownMenu: FC<DropdownMenuProps> = ({ onSignOut, onEditProfile }) => {
  return (
    <div className={styles.dropdownMenu}>
      <button onClick={onEditProfile} className={styles.dropdownItem}>Edit Profile</button>
      <button onClick={onSignOut} className={styles.dropdownItem}>Sign Out</button>
    </div>
  );
};

export default DropdownMenu;
