import { getAuth } from "firebase/auth";
import { useState, useEffect } from "react";
import { getCategories } from "../../firebaseFunctions";
import { UserInterestCategories } from "../../entities/UserProfile";
import styles from './CompleteProfile.module.css';
import { updateUserPrefs, validateUserProfileForm } from "./CommonProfile";

const auth = getAuth();

interface CompleteProfileProps {
    onCompletion: () => void; // The completion handler
}

export const CompleteProfile: React.FC<CompleteProfileProps> = ({ onCompletion }) => {
    const [categories, setCategories] = useState<UserInterestCategories[] | null>(null);
    const [loading, setLoading] = useState(false);
    const [selectedCategories, setSelectedCategories] = useState<Map<string, UserInterestCategories>>(new Map());
    const [postCodeInput, setPostCodeInput] = useState('');
    const [name, setName] = useState('');
    const [feedbackMessage, setFeedbackMessage] = useState('');
    const [formValid, setFormValid] = useState(false);
    const [pastMoments, setPastMoments] = useState('');

    const toggleCategory = (category: UserInterestCategories) => {
        console.log(`Selected category: ${category.name}`)
        setSelectedCategories((prevSelected) => {
            const newSelected = new Map(prevSelected);
            if (newSelected.has(category.id)) {
                newSelected.delete(category.id);
            } else {
                newSelected.set(category.id, { id: category.id, name: category.name, emoji: category.emoji });
            }
            return newSelected;
        });
    };

    const updateUserPrefsLocal = () => {
        setFeedbackMessage("");
        const validation = validateUserProfileForm(name, postCodeInput, selectedCategories.size);

        if (!validation.isValid) {
            setFeedbackMessage(validation.message);
            return;
        }

        // Proceed with updating user preferences if validation passes
        const userId = auth.currentUser?.uid;
        if (userId) {
            setLoading(true);
            updateUserPrefs(userId, name, selectedCategories, pastMoments, postCodeInput, true, () => {
                setLoading(false);
                onCompletion();
            });
        }
    };

    useEffect(() => {
        // Real-time validation for immediate feedback and button state
        const validation = validateUserProfileForm(name, postCodeInput, selectedCategories.size);
        setFeedbackMessage(validation.message);
        setFormValid(validation.isValid);
    }, [postCodeInput, selectedCategories]);

    useEffect(() => {
        const fetchCategories = async () => {
            setLoading(false);
            try {
                const categoriesList = await getCategories();

                setCategories(categoriesList);
            } catch (error) {
                console.error("Error fetching categories: ", error);
            } finally {
                setLoading(false);
            }
        };

        fetchCategories();
    }, []);

    if (loading) {
        <div className="centered-screen"><div className="loader"></div></div>;
    }

    return (
        <div className={styles.userProfileSetup}>
            <div className={styles.title}>Complete your Profile</div>
            <div className={styles.nameInput}>
                {name && <label htmlFor="nameInput">Name</label>}
                <input
                    id="nameInput"
                    type="text"
                    placeholder="Enter your name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className={styles.inputField}
                />
            </div>

            <div className={styles.locationInput}>
                {postCodeInput && <label htmlFor="postcodeInput">Postcode</label>}
                <input
                    id="postcodeInput"
                    type="text"
                    placeholder="Enter your postcode"
                    value={postCodeInput}
                    onChange={(e) => setPostCodeInput(e.target.value)}
                    className={styles.inputField}
                />
            </div>

            <div className={styles.pastMomentsInput}>
                <label htmlFor="pastMomentsInput">Past Moments</label>
                <textarea
                    id="pastMomentsInput"
                    placeholder="Describe some activities you have enjoyed in the past, especially during childhood."
                    value={pastMoments}
                    maxLength={700}
                    onChange={(e) => setPastMoments(e.target.value)}
                    className={styles.textareaField}
                />
            </div>

            <div className={styles.categoryParent}>
                <h4 className="blueText">Select categories of interest</h4> {/* Adjust the header to match style */}
                <div className={styles.categoryContainer}>
                    {categories?.map((category) => (
                        <div
                            key={category.id}
                            onClick={() => toggleCategory(category)}
                            className={`${styles.categoryItem} ${selectedCategories.has(category.id) ? styles.selected : ''}`}
                        >
                            <div className={styles.emoji}>{category.emoji}</div>
                            <div style={{ backgroundColor: 'transparent' }}>{category.name}</div>
                        </div>
                    ))}
                </div>
            </div>

            <div className="d-flex justify-content-center"> {/* Use a similar button style as in EditProfile */}
                <button onClick={updateUserPrefsLocal} className="custom-button mt-4 px-4 py-2" disabled={!formValid}>
                    Complete Profile
                </button>
            </div>
            {feedbackMessage && <div className={styles.feedbackMessage}>{feedbackMessage}</div>} {/* Consistent feedback message display */}
        </div>
    );
};

