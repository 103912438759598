import React, { useState, useEffect } from "react";
import { getAuth } from "firebase/auth";
import { getCategories, getUserProfile } from "../../firebaseFunctions";
import { UserInterestCategories } from "../../entities/UserProfile";
import styles from './CompleteProfile.module.css';
import { updateUserPrefs, validateUserProfileForm } from "./CommonProfile";

const auth = getAuth();

interface EditProfileProps {
    onCompletion: () => void; // The completion handler
}

export const EditProfile: React.FC<EditProfileProps> = ({ onCompletion }) => {
    const [categories, setCategories] = useState<UserInterestCategories[] | null>(null);
    const [loadingUser, setLoadingUser] = useState(false); // Loading state for user data
    const [loadingCategories, setLoadingCategories] = useState(false); // Loading state for categories
    const [selectedCategories, setSelectedCategories] = useState<Map<string, UserInterestCategories>>(new Map());
    const [postCodeInput, setPostCodeInput] = useState('');
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState('');
    const [name, setName] = useState('');
    const [formValid, setFormValid] = useState(false);
    const [pastMoments, setPastMoments] = useState('');

    const toggleCategory = (category: UserInterestCategories) => {
        setSelectedCategories((prevSelected) => {
            const newSelected = new Map(prevSelected);
            if (newSelected.has(category.id)) {
                newSelected.delete(category.id);
            } else {
                newSelected.set(category.id, { id: category.id, name: category.name, emoji: category.emoji });
            }
            return newSelected;
        });
    };

    useEffect(() => {
        // Real-time validation for immediate feedback and button state
        const validation = validateUserProfileForm(name, postCodeInput, selectedCategories.size);
        setFeedbackMessage(validation.message);
        setFormValid(validation.isValid);
    }, [postCodeInput, selectedCategories]);

    // Load user's current preferences
    useEffect(() => {
        const fetchUserData = async () => {
            setLoadingUser(true);
            const userId = auth.currentUser?.uid;
            if (userId) {
                try {
                    const userProfile = await getUserProfile(userId);
                    if (userProfile) {
                        setName(userProfile?.name ?? "");
                        setPostCodeInput(userProfile?.postcode ?? "");
                        setSelectedCategories(new Map(userProfile.items.map(item => [item.id, item])));
                        setIsSubscribed(userProfile.isSubscribedToEmails);
                        setPastMoments(userProfile.pastMoments ?? "");
                    }
                } catch (error) {
                    console.error("Error fetching user data: ", error);
                } finally {
                    setLoadingUser(false);
                }
            } else {
                setLoadingUser(false);
            }
        };

        fetchUserData();
    }, []);

    useEffect(() => {
        const fetchCategories = async () => {
            setLoadingCategories(true);
            try {
                const categoriesList = await getCategories();
                setCategories(categoriesList);
            } catch (error) {
                console.error("Error fetching categories: ", error);
            } finally {
                setLoadingCategories(false);
            }
        };

        fetchCategories();
    }, []);

    const updateUserPrefsLocal = () => {
        setFeedbackMessage("");
        const validation = validateUserProfileForm(name, postCodeInput, selectedCategories.size);

        if (!validation.isValid) {
            setFeedbackMessage(validation.message);
            return;
        }

        // Proceed with updating user preferences if validation passes
        const userId = auth.currentUser?.uid;
        if (userId) {
            setLoadingUser(true);
            updateUserPrefs(userId, name, selectedCategories, pastMoments, postCodeInput, isSubscribed, () => {
                setLoadingUser(false);
                onCompletion();
            });
        }
    };

    // Combine loading states for rendering decisions
    const isLoading = loadingUser || loadingCategories;

    // Render the component with pre-filled data
    // Rest of the rendering logic...
    if (isLoading) {
        return <div>Loading...</div>; // Show a loading indicator or similar message
    }

    return (<div className={styles.userProfileSetup}>
         <div className={styles.title}>Edit Profile</div>

         <div className={styles.nameInput}>
                {name && <label htmlFor="nameInput">Name</label>}
                <input
                    id="nameInput"
                    type="text"
                    placeholder="Enter your name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className={styles.inputField}
                />
            </div>

        <div className={styles.locationInput}>
            {postCodeInput && <label htmlFor="postcodeInput">Postcode</label>}
            <input
                id="postcodeInput" // Add an ID to associate the label
                type="text"
                placeholder="Enter your postcode"
                value={postCodeInput}
                className={styles.inputField}
                onChange={(e) => setPostCodeInput(e.target.value)}
            />
        </div>

        <div className={styles.pastMomentsInput}>
                <label htmlFor="pastMomentsInput">Past Moments</label>
                <textarea
                    id="pastMomentsInput"
                    placeholder="Describe some activities you have enjoyed in the past, especially during childhood."
                    value={pastMoments}
                    onChange={(e) => setPastMoments(e.target.value)}
                    maxLength={700}
                    className={styles.textareaField}
                />
            </div>

        <div className={styles.categoryParent}>
            <h4 className="blueText">Select categories of interest</h4>
            <div className={styles.categoryContainer}>
                {categories?.map((category) => (
                    <div
                        key={category.id}
                        onClick={() => toggleCategory(category)}
                        className={`${styles.categoryItem} ${selectedCategories.has(category.id) ? styles.selected : ''}`}
                    >
                        <div style={{ backgroundColor: 'transparent' }}>{category.emoji}</div>
                        <div style={{ backgroundColor: 'transparent' }}>{category.name}</div>
                    </div>
                ))}
            </div>
        </div>


        <div className={styles.emailSubscription}>
            <input
                id="emailSubscriptionCheckbox"
                type="checkbox"
                checked={isSubscribed}
                onChange={(e) => setIsSubscribed(e.target.checked)}
            />
            <label htmlFor="emailSubscriptionCheckbox">Subscribe to emails</label>
        </div>

        <div className="d-flex justify-content-center">
                <button onClick={updateUserPrefsLocal} className="custom-button mt-4 px-4 py-2" disabled={!formValid}>
                    Update Profile
                </button>
            </div>
            {feedbackMessage && <div className={styles.feedbackMessage}>{feedbackMessage}</div>}
    </div>);
};
